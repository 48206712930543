<template>
  <div class="room-composition">
    <div class="row">
      <div class="col-lg-8">
        <div class="phone_right" v-if="!associated && information">
          <h4>{{ $t("product-page.for-your-information") }}</h4>
          <!-- <ul v-html="text"></ul> -->
          <div v-html="information"></div>
        </div>
      </div>
      <div class="col-lg-4">
       <a :href="`tel:${phoneNumber}`">
        <div class="phone_box d-flex">
          <div class="iconphone ml-3 px-2">
            <img :src="`${imageDomain}/assets/img/phone.png`" alt="phone" width="28" height="28"/>
          </div>
          <div>
            {{ $t("product-page.encounter-message") }}
            <p style="text-align:center; font-size:25px;">{{phoneNumber}}</p>
          </div>
        </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';
import mixinTheme from '@/components/mixins/mixinTheme';

const { VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER } = process.env;

export default {
  mixins: [imageUrlMixin, mixinTheme],
  props: {
    information: {
      type: String,
      default: '',
    },
    associated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    phoneNumber() {
      const utmSource = this.$route.query.utm_source;
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : VUE_APP_FACEBOOK_PHONE_NUMBER;
      // eslint-disable-next-line no-nested-ternary
      return utmSource ? phone : this.hostData?.phoneNumber || this.whiteLabel.phoneNumber;
    },
  },
  watch: {
    lang() {
      this.changeText();
    },
  },
  beforeMount() {
    this.changeText();
  },
  methods: {
    changeText() {
      let information = this.information || this.$t('product-page.product-detail-information');
      information = information.replaceAll('\n\n', '\n');
      this.text = this.replaceURLToLink(information);
      // this.text = information
      //   .split(".")
      //   .map((item) => {
      //     return `<li>${item}.</li>`;
      //   })
      //   .join("");
    },
    replaceURLToLink(pContent) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return pContent.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    },
  },
};
</script>
